
import React from 'react';
import './About.scss';

function About() {
    return (
        <div className="about container">
            <div className="row">
                <div className="col">
                    <h2>Contact</h2>
                </div>
            </div>
            <div className="row">
                <div className="col">
                    <div className="card">
                        <div className="card-body">
                            <h5 className="card-title">Voetverzorging Petra</h5>
                            <address className="card-text">
    Buitenwaarden 12<br />
    8277 AZ, Grafhorst<br />
                                <abbr title="Telefoon">tel.</abbr> 038 - 332 65 31
  </address>
                        </div>
                    </div>
                </div>
            </div>
            <div className="row">
                <div className="col">
                    <iframe title="location"
                        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2424.1524875993327!2d5.93471661600073!3d52.584936239166915!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x47c8787dbf4b9331%3A0x66016cc091d6ffda!2sVoetverzorging%20Petra!5e0!3m2!1snl!2snl!4v1595687491136!5m2!1snl!2snl"
                        className="about-map"
                        aria-hidden="false"></iframe>
                </div>
            </div>
        </div>
    );
}

export default About;