import React, { useState } from 'react';
import {
    BrowserRouter as Router,
    Switch,
    Route,
    Link
} from 'react-router-dom';
import Home from './views/Home';
import About from './views/About';
import Threatments from './views/Threatments';

import logo from './logo2.svg';

function App() {
    const [view, setView] = useState('');

    return (
        <Router>
            <div className="d-flex flex-column flex-md-row align-items-center p-3 px-md-4 mb-3 bg-white border-bottom shadow-sm">
                <div className="my-0 mr-md-auto font-weight-normal">
                    <h4 className="mt-5 align-top d-inline-block">Voetverzorging</h4>
                    <img src={logo} height="100" alt="" loading="lazy" />
                </div> 
                <nav className="my-2 my-md-0 mr-md-3">
                    <Link className="p-2 text-dark active" to="/">Contact</Link>
                    {/* <Link className={`p-2 text-dark ${view === '' ? 'active' : ''}`} to="/" onClick={() => setView('')}>Over mij</Link>
                    <Link className={`p-2 text-dark ${view === 'behandelingen' ? 'active' : ''}`} to="/behandelingen" onClick={() => setView('behandelingen')}>Behandelingen</Link>
                    <Link className={`p-2 text-dark ${view === 'contact' ? 'active' : ''}`} to="/contact" onClick={() => setView('contact')}>Contact</Link> */}
                </nav>
            </div>
            <div className="container">
                <Switch>
                    <Route exact path="/">
                        <About />
                    </Route>
                    {/* <Route exact path="/">
                        <Home />
                    </Route>
                    <Route path="/behandelingen">
                        <Threatments />
                    </Route>
                    <Route path="/contact">
                        <About />
                    </Route> */}
                </Switch>
            </div>
        </Router>
    );
}

export default App;
